<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 1.5C2.34665 1.5 1 2.84665 1 4.5V8.5C1 10.1533 2.34665 11.5 4 11.5H8C9.65335 11.5 11 10.1533 11 8.5V8C11 7.86739 10.9473 7.74021 10.8536 7.64645C10.7598 7.55268 10.6326 7.5 10.5 7.5C10.3674 7.5 10.2402 7.55268 10.1464 7.64645C10.0527 7.74021 10 7.86739 10 8V8.5C10 9.61664 9.11665 10.5 8 10.5H4C2.88335 10.5 2 9.61664 2 8.5V4.5C2 3.38335 2.88335 2.5 4 2.5H4.5C4.63261 2.5 4.75979 2.44732 4.85355 2.35355C4.94732 2.25979 5 2.13261 5 2C5 1.86739 4.94732 1.74021 4.85355 1.64645C4.75979 1.55268 4.63261 1.5 4.5 1.5H4ZM6.5 1.5C6.36739 1.5 6.24021 1.55268 6.14645 1.64645C6.05268 1.74021 6 1.86739 6 2C6 2.13261 6.05268 2.25979 6.14645 2.35355C6.24021 2.44732 6.36739 2.5 6.5 2.5H9.29297L6.14648 5.64648C6.05274 5.74025 6.00008 5.86741 6.00008 6C6.00008 6.13259 6.05274 6.25975 6.14648 6.35352C6.24025 6.44726 6.36741 6.49992 6.5 6.49992C6.63259 6.49992 6.75975 6.44726 6.85352 6.35352L10 3.20703V6C10 6.13261 10.0527 6.25979 10.1464 6.35355C10.2402 6.44732 10.3674 6.5 10.5 6.5C10.6326 6.5 10.7598 6.44732 10.8536 6.35355C10.9473 6.25979 11 6.13261 11 6V2C10.9995 1.89625 10.9667 1.79523 10.9062 1.71094C10.9056 1.70898 10.905 1.70703 10.9043 1.70508C10.903 1.70377 10.9017 1.70247 10.9004 1.70117C10.8861 1.68184 10.8704 1.66356 10.8535 1.64648C10.8428 1.63823 10.8317 1.63041 10.8203 1.62305C10.8007 1.60649 10.7798 1.59147 10.7578 1.57812C10.7372 1.56484 10.7157 1.55309 10.6934 1.54297C10.6668 1.53217 10.6393 1.52367 10.6113 1.51758C10.5876 1.51191 10.5634 1.50799 10.5391 1.50586C10.5261 1.50339 10.5131 1.50144 10.5 1.5H6.5Z"
      fill="#1373D9"
    />
  </svg>
</template>
